import React, { memo } from "react";

import { Text } from "@chakra-ui/react";
import { Row } from "antd";

const ExitOnb = memo(() => {
  return (
    <div>
      <Row gutter={[16, 16]}></Row>
    </div>
  );
});
ExitOnb.displayName = "ExitOnb";
export default ExitOnb;
