import React, { memo, useState, useEffect } from "react";
import Navbar from "components/Navbar/Navbar";
import { postSelfie, postStart } from "service/newNaturalClient/formOne";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
import { useNavigate, useParams } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { ThemeProvider } from "@aws-amplify/ui-react";
import awsexports from "../../../../aws-exports";
import Error from "../../../../assets/icons/error.svg";
import IconFace from "../../../../assets/icons/face-scan.svg";
import BadSelfie from "../../../../assets/icons/bad-selfie.svg";
import IdealSelfie from "../../../../assets/icons/ideal-selfie.svg";
import CompareIcon from "../../../../assets/icons/compare-icon.svg";
import Icon from "../../../../assets/icons/info-warning.svg";

import "./selfie.scss";
import "../../../../styles/style.scss";
import "../../../../styles/colors.scss";
import "@aws-amplify/ui-react/styles.css";
import { Row } from "antd";

const LivenessStep = memo(() => {
  const [prevScreen, setPrevScreen] = useState("FIRST");
  const [errorSelfie, setErrorSelfie] = useState(
    "Ha ocurrido un error en el proceso. Intente entrar en el marco."
  );
  const [createLivenessApiData, setCreateLivenessApiData] = React.useState({
    sessionId: null,
  });

  const { userId, uuid } = useParams();
  Amplify.configure(awsexports);
  const navigate = useNavigate();
  const [idSessionEffect, setIdSessionEffect] = useState(true);
  const [image64, setImage64] = useState(null);

  const [failedAttempts, setFailedAttempts] = useState(0);
  const [attemptType, setAttemptType] = useState("SELFIE_CAPTURE");
  const token = sessionStorage.getItem("newCheckToken");
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [cameraError, setCameraError] = useState(null);

  const handleStartCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());
      setPermissionStatus("granted");
      setPrevScreen("SELFIE");
      setIdSessionEffect(!idSessionEffect);
    } catch (error) {
      setPermissionStatus("denied");
      setCameraError("Debe permitir el acceso a la cámara para continuar");
      setPrevScreen("NOTCAMERA");
   
    }
  };
  // useEffect(() => {
  //   checkCameraPermission();
  //   if (permissionStatus === "granted") {
  //     console.log("PASO POR PERMISOS STATUS", permissionStatus.state);
  //   } else {
  //     handlePer();
  //   }
  // }, []);

  // const handlePer = async () => {
  //   await navigator.mediaDevices.getUserMedia({ video: true });
  //   navigator.permissions.query({ name: 'camera' })
  //   return
  // };
  // const checkCameraPermission = async () => {
  //   try {
  //     const result = await navigator.permissions.query({ name: "camera" });
  //     setPermissionStatus(result.state);
  //     console.log("RESULTADO", result.state);
  //   } catch (error) {
  //     console.error("Error checking camera permission:", error);
  //   }
  // };

  // const requestCameraPermission = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //     console.log("STREAM", stream);
  //     if (stream) {
  //       setPermissionStatus("granted");
  //       console.log("RESULTADO STREAM", stream);
  //     }
  //   } catch (error) {
  //     if (error.name === "NotAllowedError") {
  //       setPermissionStatus("denied");
  //     } else {
  //       console.error("Error requesting camera permission:", error);
  //     }
  //   }
  // };
  // const handleCheckPermission = () => {
  //   if (permissionStatus === "granted") {
  //     return true;
  //   } else {
  //     requestCameraPermission();
  //   }
  // };

  // useEffect(() => {
  //   const permissionFetch = async () => {
  //     const permission = await navigator.mediaDevices.getUserMedia({
  //       video: true,
  //     });
  //     permissionFetch()
  //   // try {
  //   //   permissionFetch()
  //   //     console.log("PERMISOS CAMARA", permission);
  //   //   }
  //   //  catch (error) {
  //   //   console.error("PERMISOS FALLIDOS", error);
  //   // }
  // }}, []);

  useEffect(() => {
    // const permissionFetch = async () => {
    //   try {
    //     await navigator.mediaDevices.getUserMedia({
    //       video: true,
    //     });
    //   } catch (error) {
    //     console.log("ERROR PERMISOS", error);
    //     navigate("/exit")
    //   }
    // };
    // sessionStorage.removeItem("requestNumber");
    const fecthSelfie = async () => {
      const body = {
        userId: userId,
        uuid: uuid,
      };
      const resp = await postStart(body);
      sessionStorage.setItem("newCheckToken", resp.data.accessToken);
      // await permissionFetch();
    };

    fecthSelfie();
  }, []);

  const registerAttempt = async (attemptNumber, status) => {
    const body = {
      attemptType: attemptType,
      attemptNumber: String(attemptNumber),
      status: status,
    };

    try {
      await fetch("https://app3.developerpa.com/onboarding/registerAttempt", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken: `${token}`,
        },
        body: JSON.stringify(body),
      });
    } catch (error) {
      console.error("Error registering attempt:", error);
    }
  };

  const handleFailedAttempt = () => {
    setFailedAttempts((prev) => {
      const newCount = prev + 1;
      console.log(`Número de intentos fallidos: ${newCount}`);
      registerAttempt(newCount, false);
      return newCount;
    });
  };

  useEffect(() => {
    const fetchCreateLiveness = async () => {
      try {
        const resp = await fetch(
          process.env.REACT_APP_AWS_CREATESESSION_ROUTE,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Verifica si la respuesta fue exitosa
        if (!resp.ok) {
          const errorText = await resp.text(); // Obtiene el texto de la respuesta
          console.error("Error en la respuesta:", errorText);
          throw new Error(`HTTP error! status: ${resp.status}`);
        }

        const dta = await resp.json();
        const mockResponse = { sessionId: dta.sessionId };
        setCreateLivenessApiData(mockResponse);
      } catch (error) {
        console.error("Error fetching create liveness:", error);
        setErrorSelfie("Error al crear la sesión de liveness.");
        // handleFailedAttempt();
      }
    };
    fetchCreateLiveness();
  }, [idSessionEffect]);

  const handleAnalysisComplete = async () => {
    try {
      const response = await fetch(
        `https://app3.developerpa.com/aws/sessionResults/${createLivenessApiData.sessionId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then();
      const data = await response.json();
      setImage64(data.getBytes);
      console.log("ANALISIS", response);

      //PORCENTAJE DE COINCIDENCIA DE VIDA

      // Verificar el nivel de confianza
      if (data.Confidence < 70) {
        setPrevScreen("ERROR");
        // handleFailedAttempt();
        return;
      }

      const body = {
        // requestNumber: location.state.requestNumber,

        image: `data:image/jpg;base64,${data.getBytes}`,
        userId: userId,
        uuid: uuid,
      };
      const formData = new FormData();

      Object.entries(body).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const res = await postSelfie(formData);
      const accessToken = sessionStorage.getItem("newCheckToken");
      console.log("RESPUESTA SELFIE", res);
      registerAttempt(1, true);
      // SuccessToast("Registro biometrico exitoso");
      navigate(`/load/${res.data.requestNumber}/${accessToken}/success`);

      // navigate("/doc");
      // dispatch(setNewNaturalStep(parseInt(res?.data?.nextView)));

      // dispatch(setFormTrackInfo(res?.data));
    } catch (error) {
      setErrorSelfie(error.response.data.message);
      ErrorCToast(error);
      setPrevScreen("ERROR");
      handleFailedAttempt();
    } finally {
    }
  };
  const handleRetry = () => {
    const currentAttempts = handleFailedAttempt(); // Incrementar el contador de intentos
    console.log("Intento fallido registrado."); // Imprimir en consola

    if (currentAttempts >= 3) {
      navigate("error-intents");
    } else {
      setPrevScreen("FIRST");
      setIdSessionEffect(!idSessionEffect);
    }
  };

  useEffect(() => {
    if (failedAttempts >= 3) {
      navigate("error-intents");
    }
  }, [failedAttempts]);
  return (
    <div>
      {prevScreen === "FIRST" && (
        <div>
          <Navbar title="Selfie" noBackbutton={true} />
          <div
            style={{
              height: "250px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ height: "150px" }}></div>
            <img id="face-logo" src={IconFace} />
            <p
              style={{ marginTop: "16px", color: "#726F6E" }}
              className="title"
            >
              Prepárese para capturar su rostro <br />
              con tecnología biométrica
            </p>
          </div>
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <button
              style={{ marginTop: "100px" }}
              className="big-button"
              onClick={handleStartCamera}
            >
              Continuar
            </button>
          </div>
        </div>
      )}
      {createLivenessApiData.sessionId !== null ? (
        prevScreen === "SELFIE" && (
          <ThemeProvider>
            <FaceLivenessDetector
              sessionId={createLivenessApiData.sessionId}
              region="us-east-1"
              onAnalysisComplete={handleAnalysisComplete}
              onUserCancel={() => {
                setErrorSelfie("Has cancelado el proceso.");
                setPrevScreen("ERROR");
                setIdSessionEffect(!idSessionEffect);
                handleFailedAttempt();
              }}
              disableStartScreen={true}
              onError={() => {
                setIdSessionEffect(!idSessionEffect);
              }}
            />
          </ThemeProvider>
        )
      ) : (
        <h1></h1>
      )}

      {prevScreen === "ERROR" && (
        <div>
          <div className="error-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
                padding: "20px",
                margin: "20px 20px",
                backgroundColor: "#FFEEED",
                borderRadius: "20px",
              }}
            >
              <Row>
                <div
                  style={{
                    marginRight: "8px",
                  }}
                >
                  <img src={Icon} alt="Infored" />
                </div>
                <div
                  style={{
                    textAlign: "start",
                    flex: "1",
                  }}
                >
                  <p style={{}}>Error relacionado a su biometría</p>
                  <p style={{ fontWeight: "400" }}>{errorSelfie}</p>
                </div>
              </Row>
            </div>

            <Row style={{ marginBottom: "12px" }}>
              {image64 && (
                <div className="image-container">
                  <img
                    className="img"
                    src={`data:image/jpeg;base64,${image64}`}
                  />
                </div>
              )}

              {!image64 && (
                <div className="image-container">
                  <img className="img" src={BadSelfie} />
                </div>
              )}

              <div
                style={{
                  width: "20px",
                }}
              ></div>
              <img className="image-container" src={IdealSelfie} />
            </Row>
            <Row>
              <p style={{ width: "115px" }}>Su selfie</p>
              <img style={{ width: "20px" }} src={CompareIcon} alt="Infored" />
              <p style={{ width: "115px" }}>Pose ideal</p>
            </Row>

            <p style={{ marginTop: "44px" }}>Nota:</p>
            <p style={{ marginTop: "5px", fontWeight: "400" }}>
              Use una mejor postura e iluminación
            </p>
          </div>
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <button
              style={{ marginTop: "48px" }}
              className="big-button"
              onClick={handleRetry}
            >
              Volver a intentarlo
            </button>
          </div>
        </div>
      )}

      {prevScreen === "NOTCAMERA" && (
        <div>
          <div
          style={{
            height: "250px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ height: "150px" }}></div>
          <img id="face-logo" src={Error}  style={{width: "60px"}}/>

          <h3 style={{ marginTop: "20px"}}>¡Permiso de cámara requerido!</h3>
          <p
            style={{ marginTop: "8px", color: "#726F6E", fontWeight: "400" }}
            className="title"
          >
            Porfavor reiniciar la aplicación
          </p>
        </div>
        <div
          style={{
            padding: "0px 20px",
         
          }}
        >
          <button
            style={{ marginTop: "100px" }}
            className="big-button"
            onClick={() => {
              navigate("/reset")
            }}
          >
            Reiniciar App ahora 
          </button>

          <button
            style={{ marginTop: "16px" }}
            className="big-button-cancel"
            onClick={() => {
              navigate("/exit")
            }}
          >
            Continuar mas tarde
          </button>
        </div>
      </div>
      )}
    </div>
  );
});
LivenessStep.displayName = "SelfieStep";
export default LivenessStep;
